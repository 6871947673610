/* You can add global styles to this file, and also import other style files */
@use "node_modules/ngx-popperjs/css/theme-white.css" as popperBaseTheme;

#login_background{
    min-height:100vh;
}

.is-invalid{
    border:1px solid #f1416c!important;
}

.transparentNoDataImg{
    opacity:0.5!important;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
    max-width:100%!important;
}

.mw-60{
    max-width:60%;
}

.radioButtonSeparator{
    margin-bottom:10px;
    border-bottom-style: dashed;
    border-bottom-color: #e4e6e4;
    border-bottom-width: 1px;
    padding-bottom:10px;
}
.radioButtonSeparatorBorderless{
  margin-bottom:10px;
  padding-bottom:10px;
}
.select2-container{
    border-radius: 0.475rem;
}
  .toast {
    background-color: #030303;
  }
  #toast-container > .toast-info {
    background-color: #3276b1;
  }
  .toast-info2 {
    background-color: #2f96b4;
  }
  #toast-container > .toast-error {
    background-color: #bd362f;
  }
  #toast-container > .toast-success {
    background-color: #51a351;
  }
  #toast-container > .toast-warning {
    background-color: #f89406;
  }

  #background-video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }


  .aside-dark .menu .menu-item .menu-link .menu-title {
    color: #dddfff!important;
}

.aside-dark .menu .menu-item .menu-link .menu-icon, .aside-dark .menu .menu-item .menu-link .menu-icon .svg-icon, .aside-dark .menu .menu-item .menu-link .menu-icon i{
  color: #dddfff !important;
}

@media (max-width: 1440px) {
  #retailPlaceCard {
    width: 100%;
  }
}

@media (min-width: 1440px) {
  #retailPlaceCard {
    width: 70%;
  }
}

@media (max-width: 1440px) {
  #groupCard {
    width: 100%;
  }
}

@media (min-width: 1440px) {
  #groupCard {
    width: 60%;
  }
}


@media (max-width: 1440px) {
  #groupTypeCard {
    width: 100%;
  }
}

@media (min-width: 1440px) {
  #groupTypeCard {
    width: 50%;
  }
}

@media (max-width: 1440px) {
  #warehouseCard {
    width: 100%;
  }
}

@media (min-width: 1440px) {
  #warehouseCard {
    width: 60%;
  }
}


@media (max-width: 1440px) {
  #taxCard {
    width: 100%;
  }
}

@media (min-width: 1440px) {
  #taxCard {
    width: 50%;
  }
}



@media (max-width: 1440px) {
  #paymentTypeCard {
    width: 100%;
  }
}

@media (min-width: 1440px) {
  #paymentTypeCard {
    width: 50%;
  }
}

#kt_chart_global_sales {
  width: 100%;
  height: 100%;
}

#kt_chart_global_sales .apexcharts-canvas {
  height: 180px !important;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }



#btnLogin{
  background-color: #f26b38;
  border-color: #f26b38;
  color: #fff;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

#btnLogin:hover{
  background-color: #d65a30; /* tamnija nijansa za hover */
  border-color: #d65a30;
}